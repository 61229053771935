<template>
  <div v-if="showForm">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-file-multiple</v-icon>
      <v-toolbar-title>
        {{ $t('common.workspace', locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Name"
                :label="$t('sysUser.name', locale)"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
      <v-autocomplete
        v-model="editedItem.MeteoID"
        :items="zones"
        outlined
        itemText="name"
        itemValue="id"
        hide-details
        label="Meteo"
      />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.TotemBackground"
                label="TotemBackground"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.TotemScreensaver"
                label="TotemScreensaver"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <select-sys-users-permissions
                area="turismo"
                :workspaceID="editedItem.ID"
                :onChangeUser="handleChangeUser"
              />
            </v-col>
          </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :onClose="onClose"
      :locale="locale"
    />
  </div>
</template>
<script>
const defaultItem = {
  Name: '',
  Users: [],
}

import { mapState } from 'vuex'
import axios from 'axios'
import api from '@/services/api'
import SelectSysUsersPermissions from '@/components/SelectSysUsersPermissions'
import ActionButtons from '@/components/ActionButtons'
export default{
  name: 'WorkspaceForm',
  components: {
    ActionButtons,
    //SelectSysUsers,
    SelectSysUsersPermissions,
  },
  props: {
    editedID: {
      type: String,
      default: null
    },
    showForm: {
      type: Boolean,
      default: false
    },
    onDelete: {
      type: Function,
      required: true
    },
    onSave: {
      type: Function,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
  },
  data () {
    return {
      loading: false,
      editedItem: null,
      zones: null,
    }
  },
  computed: {
    ...mapState('app',['locale']),
  },
  watch: {
    showForm (v) {
      if (v) {
        if(this.editedID) this.handleGetData()
        else this.editedItem = defaultItem
      }
    },
    editedID (v) {
      if (this.showForm) {
        if (v) this.handleGetData()
        else this.editedItem = defaultItem

      }
    },
  },
  mounted () {
    this.getAllZones()
  },
  methods: {
    getAllZones () {
      const url = 'https://cm-dviewcontent-api.dviewcontent.com/v1/public/meteos/zones-turismo'
      axios.get(url)
        .then(response => {
          this.zones = response.data.map(item => {
            return {
              id: item.NombreMun,
              name: item.Lugar,
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleGetData () {
      this.loading = true
      api.getItem ('turismo', 'v1/system/workspaces/', this.editedID)
        .then(response => {
          this.editedItem = response
          this.loading = false
        })
    },
    handleChangeUser (v) {
      this.editedItem.Users = v
    },
    handleSave () {
      this.onSave(this.editedItem)
    },
    handleDelete () {
      this.onDelete(this.editedItem.ID)
    }
  }
}
</script>

